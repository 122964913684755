import axios from './axiosInit';
import store from '@/store';

import sharedServices from './shared-services';

const tokenEnrollmentModel = {
    requestUID: Math.floor(1000 + Math.random() * 9000),
    requestDate: '',
    channelKey: '',
    identityKey: '',
    channelId: store.get('requestKeys/channelId'),
    identitySeed: store.get('requestKeys/identitySeed'),
    userName: '',
}

const getTokenForEnrollment = async (data) =>
{
    try
    {
        tokenEnrollmentModel.requestUID = tokenEnrollmentModel.requestUID.toString();
        tokenEnrollmentModel.requestDate = data.requestDate;
        tokenEnrollmentModel.channelKey = data.channelKey;
        tokenEnrollmentModel.identityKey = data.identityKey;

        store.set('requestKeys/identityKey', data.identityKey);
        store.set('requestKeys/requestDate', data.requestDate);
        store.set('requestKeys/channelKey', data.channelKey);

        const payload = {
            requestInfo: tokenEnrollmentModel
        }
        const result = await axios.post('hpsbridge/se/enrolment/getToken', payload)
        return result
    } catch (e)
    {
        sharedServices.handelError('Error', 'Server Error', 'error')

    }
}

const verifyAndGetOTP = async (data) =>
{
    try
    {
        store.dispatch('loader/show');
        const payload = {
            requestInfo: {
                requestUID: (Math.floor(1000 + Math.random() * 9000)).toString(),
                requestDate: store.get('requestKeys/requestDate'),
                channelKey: store.get('requestKeys/channelKey'),
                identityKey: store.get('requestKeys/identityKey'),
                channelId: store.get('requestKeys/channelId'),
                identitySeed: store.get('requestKeys/identitySeed'),
                userName: "",
            },
            captchaId: data.captchaId,
            captchaValue: data.captchaValue,
            expiryDate: store.get('enrollment/expiryDate'),
            lastFourDigit: store.get('enrollment/lastFourDigit'),
            legalId: store.get('enrollment/legalId'),
        };

        const auth = {
            headers: {
                Authorization: 'Bearer ' + store.get('requestKeys/enrollmentToken')
            }
        }

        const result = await axios.post('hpsbridge/se/enrolment/verifyCaptchaAndGetOtp', payload, auth)
        store.dispatch('loader/hide');
        return result
    } catch (e)
    {
        store.dispatch('loader/hide');
        sharedServices.handelError('Error', 'Server Error', 'error')
    }
}

const verifyOPTAndGetDetails = async (data) =>
{
    try
    {
        store.dispatch('loader/show');

        const payload = {
            requestInfo: {
                requestUID: (Math.floor(1000 + Math.random() * 9000)).toString(),
                requestDate: store.get('requestKeys/requestDate'),
                channelKey: store.get('requestKeys/channelKey'),
                identityKey: store.get('requestKeys/identityKey'),
                channelId: store.get('requestKeys/channelId'),
                identitySeed: store.get('requestKeys/identitySeed'),
                userName: "",
            },
            captchaId: data.captchaId,
            captchaValue: data.captchaValue,
            expiryDate: store.get('enrollment/expiryDate'),
            lastFourDigit: store.get('enrollment/lastFourDigit'),
            legalId: store.get('enrollment/legalId'),
            otp: data.otp
        };

        const auth = {
            headers: {
                Authorization: 'Bearer ' + store.get('requestKeys/enrollmentToken')
            }
        }

        const result = await axios.post('hpsbridge/se/enrolment/getDetails', payload, auth)
        store.dispatch('loader/hide');

        return result
    } catch (e)
    {
        sharedServices.handelError('Error', 'Server Error', 'error')
    }
}

const saveUserData = async (data) =>
{
    try
    {
        store.dispatch('loader/show');
        const payload = {
            requestInfo: {
                requestUID: (Math.floor(1000 + Math.random() * 9000)).toString(),
                requestDate: data.tokenRes.data.results.requestDate,
                channelKey: data.tokenRes.data.results.channelKey,
                identityKey: data.tokenRes.data.results.identityKey,
                channelId: store.get('requestKeys/channelId'),
                identitySeed: store.get('requestKeys/identitySeed'),
                userName: data.dict.userName,
            },
            expiryDate: store.get('enrollment/expiryDate'),
            lastFourDigit: store.get('enrollment/lastFourDigit'),
            legalId: store.get('enrollment/legalId'),
            password: data.dict.password
        };

        const auth = {
            headers: {
                Authorization: 'Bearer ' + store.get('requestKeys/enrollmentToken')
            }
        }

        const result = await axios.post('hpsbridge/se/enrolment/user', payload, auth)
        store.dispatch('loader/hide');

        return result
    } catch (e)
    {
        sharedServices.handelError('Error', 'Server Error', 'error')

    }
}


export default {
    getTokenForEnrollment,
    verifyAndGetOTP,
    verifyOPTAndGetDetails,
    saveUserData,
}
