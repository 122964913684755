<template>
    <div>
        <div v-if="showAlert">
            <alert :message="alertMessage"
                   :type="alertType"></alert>

        </div>
        <v-form ref="loginForm"
                class="multi-col-validation"
                lazy-validation
                v-model="valid">
            <div>
                <v-row class="d-flex">
                    <v-col cols="12"
                           lg="6"
                           md="5"
                           sm="12">
                        <v-icon class="arabic-arrow"
                                color="green-darken-2"
                                style="font-size: 31px;cursor: pointer;"
                                @click="back">
                            mdi-arrow-left
                        </v-icon>
                        <p class="arabic-font text-lg-2xl text-xl font-weight-semibold text-black pt-5"
                           style="margin-bottom: 0;">
                            {{ $t('Enrollment Step') }} {{ '1' }} {{ $t('of') }} 3

                        </p>
                        <p class="arabic-font font-weight-semibold text-color-black mb-0 mt-4">
                            <!-- {{ $t('Enter the last four digits of your card number.') }} -->
                            {{ $t('Let’s start with your details') }}

                        </p>

                        <v-row class="d-flex">
                            <v-col cols="12"
                                   lg="7"
                                   md="7"
                                   sm="12"
                                   dir="ltr">
                                <p class="arabic-font arabic-otp font-weight-semibold mb-4 mt-3 text-color-black"
                                   style="font-size: 13px;">

                                    {{ $t("Enter Card's last 4 digits") }}
                                </p>
                                <v-otp-input v-model="lastFourDigit"
                                             :rules="cards"
                                             @input="onOtpInput"
                                             @blur="onInputBlur"
                                             ref="otpInput"
                                             :hide-details="true"
                                             type="number"
                                             @finish="onFinish"
                                             onkeydown="javascript: return event.keyCode == 69 ? false : true"
                                             length="4">
                                </v-otp-input>
                                <div v-if="otpError"
                                     style="font-size: 12px;"
                                     class="error-message">
                                    {{ $t(otpErrorMessage) }}
                                </div>
                            </v-col><v-col cols="12"
                                   lg="5"
                                   md="5"
                                   sm="12">
                                <p class="arabic-font font-weight-semibold pt-1 mb-5 mt-2 text-color-black"
                                   style="font-size: 13px;">
                                    {{ $t("Enter Card's expiry date") }}
                                </p>

                                <v-text-field v-model="maskedExpiryDate"
                                              outlined
                                              maxlength="5"
                                              :placeholder="$t('MM-YY')"
                                              v-mask="timeMask"
                                              :rules="expiry"
                                              hide-details="auto"
                                              class="mb-2">
                                    <template #message="{ message }">
                                        {{ $t(message) }}
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12"
                                   lg="12"
                                   md="12"
                                   sm="12">
                                <p class="arabic-font font-weight-semibold text-color-black mb-4"
                                   style="font-size: 13px;">

                                    {{ $t('Please enter your Personal ID/Iqama number') }}
                                </p>
                                <v-text-field v-model="legalId"
                                              outlined
                                              type="text"
                                              :placeholder="$t('Enter Personal ID/Iqama number')"
                                              :rules="nationalRules"
                                              hide-details="auto"
                                              class="mb-2">
                                    <template #message="{ message }">
                                        {{ $t(message) }}
                                    </template>
                                </v-text-field>

                            </v-col>
                            <v-col cols="12"
                                   lg="12">
                                <p class="arabic-font font-weight-semibold text-color-black mb-2"
                                   style="font-size: 14px;">
                                    <!-- {{ $t('Enter the last four digits of your card number.') }} -->
                                    {{ $t('Security Check') }}
                                </p>
                            </v-col>
                            <v-col cols="12"
                                   lg="12"
                                   md="12"
                                   sm="12"
                                   class="security"
                                   style="margin: 0px -13px;padding: 8px 27px;">
                                <v-col cols="12"
                                       lg="12"
                                       md="12"
                                       sm="12">
                                    <p class="arabic-font font-weight-semibold mb-5 mt-0 text-blue"
                                       style="font-size: 13px;">

                                        {{ $t('Please enter the characters in image') }}
                                    </p>
                                    <div style="display:flex">

                                        <div style="width: 161px;height: 40px;">
                                            <v-img contain
                                                   max-width="100%"
                                                   class="auth-3d-group overflow-hidden"
                                                   :src="captchaImage">
                                            </v-img>

                                        </div>
                                        <span class="ar-referesh-btn referesh-btn">
                                            <v-btn icon
                                                   color="primary"
                                                   type="button"
                                                   @click="requestCaptcha"
                                                   large
                                                   class="py-3 mt-1">
                                                <v-icon>mdi-refresh</v-icon>
                                            </v-btn>
                                        </span>

                                    </div>
                                </v-col>
                                <v-col lg="12">
                                    <div>
                                        <div class="arabic-font">
                                            <v-text-field v-model="captchaValue"
                                                          outlined
                                                          type="text"
                                                          :placeholder="$t('Enter the characters')"
                                                          v-mask="'######'"
                                                          :rules="captchaRules"
                                                          hide-details="auto"
                                                          class="mb-2 captcha-input">
                                                <template #message="{ message }">
                                                    {{ $t(message) }}
                                                </template>
                                            </v-text-field>
                                        </div>
                                        <!-- <v-btn color="primary"
                               type="button"
                               style="height: 57px !important;"
                               :disabled="legalId.length < 10 || captchaValue.length < 6 || lastFourDigit.length < 4 || expiryDate.length < 7 "
                               @click="verifyAndGetOTP"
                               large
                               class="py-3 mx-3">

                            {{ $t('Verify') }}
                        </v-btn> -->
                                    </div>
                                </v-col>
                            </v-col>
                            <v-col lg="12"
                                   md="12"
                                   sm="12">
                                <v-btn block
                                       color="primary"
                                       type="button"
                                       @click="submit"
                                       :disabled="legalId.length < 10 || captchaValue.length < 6 || lastFourDigit.length < 4 || maskedExpiryDate.length < 5"
                                       x-large
                                       class="arabic-font">

                                    {{ $t('Next') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col class="divide">
                        <v-divider class="my-2"
                                   :vertical="true"></v-divider>
                    </v-col>
                    <v-col lg="5"
                           md="5"
                           sm="12">

                        <div class="text-center">
                            <p class="arabic-font font-weight-semibold text-color-black mb-0 pt-5"
                               style="font-size: 13px;">
                                {{ $t('Welcome') }}
                            </p>
                            <p class="arabic-font text-2xl font-weight-semibold text-black"
                               style="margin-bottom: 0;">
                                {{ $t('Register your card') }}
                            </p>
                            <div style="width:180px;margin: 0 auto;">
                                <v-img contain
                                       max-width="180px"
                                       class="auth-3d-group overflow-hidden mt-3"
                                       :src="require(`@/assets/images/logos/card.png`)">
                                </v-img>
                            </div>
                            <p class="arabic-font font-weight-semibold text-color-black mb-0 mt-6"
                               style="font-size: 14px;">
                                {{ $t('To setup your new card, you will need:') }}
                            </p>

                            <div class="arabic-font enrol-card mt-5"
                                 style="width: 251px;margin: 0 auto;">
                                <p class="d-flex align-center">
                                    <span><img src="@/assets/images/logos/card-logo.png"
                                             width="22px"></span>
                                    <span class="mx-2"
                                          style="font-size: 13px;">{{ $t('Your American Express Card') }} </span>
                                </p>
                                <p class="arabic-iqama d-flex align-center">
                                    <span><img src="@/assets/images/logos/account.png"
                                             width="20px"></span>

                                    <span class="arabic-text mx-2"
                                          style="font-size: 13px;">{{ $t('Personal ID/Iqama number') }}</span>
                                </p>

                                <p class="arabic-paragraph d-flex align-center">
                                    <span><img src="@/assets/images/logos/security.png"
                                             width="29px"></span>
                                    <span style="font-size: 13px;"
                                          class="security-text mx-2">{{ $t('Create a 6-digit passcode') }}</span>
                                </p>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </v-form>
    </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline

import { required } from '@core/utils/validation';
import { ref } from '@vue/composition-api'
import alert from '../alert.vue';
import store from '@/store'

import AppBarThemeSwitcher from '@core/layouts/components/app-bar/AppBarThemeSwitcher.vue'
import sharedServices from '@/services/shared-services'
import enrollmentServices from '@/services/enrollment-services'

import restErrorMessages from '@/@core/utils/rest-error-messages';

export function timeMask(value)
{
    const month = [
        /[0-1]/, value.charAt(0) === '0' ? /[0-9]/ : /[0-2]/,
    ];
    const year = [/[0-9]/, /[0-9]/];
    return value.length > 2 ? [...month, '-', ...year] : month;
}

export default {
    components: {
        AppBarThemeSwitcher,
        alert
    },

    data: () => ({
        timeMask,
        valid: false,
        lastFourDigit: '',
        maskedExpiryDate: '',
        expiryDate: '',
        otpError: false,
        otpErrorMessage: '',
        cards: [
            (value) => !!value || 'Last 4 digits are required.'
        ],
        disableSubmit: true,
        enableNext: false,
        legalId: '', // From User Input
        captchaValue: '', // From UserInput
        captchaId: '', // From API
        captchaImage: '', // From API to Display Captcha Image
        genericRules: [
            v => !!v || 'Field is required'
        ],
        alertMessage: '',
        alertType: 'error',
        showAlert: false,
        expiry: [
            (value) => !!value || 'Expiry date is required.',
            (value) => (value && value.length >= 5) || 'Expiry date is required.',
        ],
        captchaRules: [
            (value) => !!value || 'Captcha value is required.',
            (value) => (value && value.length >= 6) || 'Captcha must be 6 digits',
        ],

        nationalRules: [
            (value) => !!value || 'Personal ID is required.',

        ],
    }),
    mounted()
    {
        window.scrollTo(0, 0);
        store.dispatch('loader/show');
        sharedServices.getToken('enr', "").then(tokenRes =>
        {
            enrollmentServices.getTokenForEnrollment(tokenRes.data.results).then(enrollmentTokenRes =>
            {
                store.set('requestKeys/enrollmentToken', enrollmentTokenRes.data.accessToken);
                this.requestCaptcha();
            })
        })

    },
    methods: {
        onFinish(rsp)
        {
        },
        onOtpInput()
        {
            if (this.lastFourDigit.length !== 4)
            {
                this.otpError = true;
                this.otpErrorMessage = 'Last 4 digits are required.';
                const inputElement = this.$refs.otpInput.$el.querySelector('input');
                if (inputElement)
                {
                    inputElement.focus(); // Move focus back into the input
                }
            } else
            {
                this.otpError = false;
            }
        },
        onInputBlur()
        {
            if (!this.lastFourDigit.length)
            {
                this.otpError = true;
                this.otpErrorMessage = 'Last 4 digits are required.';
            }
        },
        back()
        {
            this.$emit('back');
        },


        submit()
        {
            // store.set('enrollment/lastFourDigit', this.lastFourDigit)
            // store.set('enrollment/expiryDate', this.expiryDate)
            // this.$emit('submit')
            this.verifyAndGetOTP()
        },
        requestCaptcha()
        {
            sharedServices.getCaptcha().then(captchaResponse =>
            {
                this.captchaImage = 'data:image/jpg;base64,' + captchaResponse.data.captcha;
                this.captchaId = captchaResponse.data.captchaId;
            })
        },
        verifyAndGetOTP()
        {
            let dict = {
                captchaId: this.captchaId,
                captchaValue: this.captchaValue,
            }

            enrollmentServices.verifyAndGetOTP(dict).then(otpResponse =>
            {
                // console.log(otpResponse);
                if (otpResponse?.data?.responseInfo?.errorCode == "00000")
                {
                    this.enableNext = true;
                    this.$emit('submit')
                }
                else
                {
                    if (otpResponse?.data?.responseInfo?.errorCode != "00000")
                    {
                        this.alertMessage = restErrorMessages.get(otpResponse?.data?.responseInfo?.errorCode ? otpResponse?.data?.responseInfo?.errorCode : otpResponse?.error?.response?.data?.responseInfo?.errorCode, otpResponse?.data?.responseInfo?.errorDescription ? otpResponse?.data?.responseInfo?.errorDescription : otpResponse?.error?.response?.data?.responseInfo?.errorDescription);
                        this.alertType = 'error'
                        this.showAlert = true
                        setTimeout(() =>
                        {
                            this.showAlert = false
                        }, 5000)
                    }

                    else if (otpResponse?.error?.response?.status != 200)
                    {
                        this.alertMessage = restErrorMessages.get(otpResponse?.data?.responseInfo?.errorCode, otpResponse?.data?.responseInfo?.errorDescription ? otpResponse?.data?.responseInfo?.errorDescription : otpResponse?.error?.response?.data?.responseInfo?.errorDescription);
                        this.alertType = 'error'
                        this.showAlert = true
                        setTimeout(() =>
                        {
                            this.showAlert = false
                        }, 5000)
                    }
                }

            })
        },
    },
    watch: {
        lastFourDigit: function (newVal, oldVal)
        {
            if (newVal == 'e')
            {
                alert('knskfdkfd')
            }
            if (newVal.length == 4)
            {
                store.set('enrollment/lastFourDigit', this.lastFourDigit)
                if (this.maskedExpiryDate.length == 5 && this.legalId != '')
                {

                    this.disableSubmit = false;
                }
                else
                {
                    this.disableSubmit = true;
                }
            }
            else
            {
                this.disableSubmit = true;
            }
        },
        legalId: function (newVal, oldVal)
        {
            store.set('enrollment/legalId', newVal);
        },
        maskedExpiryDate: function (newVal, oldVal)
        {
            if (newVal.length == 5)
            {
                let finalExpiry = newVal.split('-').map(s => s.trim());
                finalExpiry[1] = '-20' + finalExpiry[1]
                this.expiryDate = finalExpiry[0] + finalExpiry[1]
                store.set('enrollment/expiryDate', this.expiryDate)

                if (this.lastFourDigit.length == 4)
                {
                    this.disableSubmit = false;
                }
                else
                {
                    this.disableSubmit = true;
                }
            }
            else
            {
                this.disableSubmit = true;
            }
        }
    },
    setup()
    {
        // Template Ref
        const loginForm = ref(null)

        const email = ref('')
        const errorMessages = ref([])

        return {
            errorMessages,
            validators: {
                required
            },

            // Template Refs
            loginForm,
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/auth.scss';

.referesh-btn {
    background: #D9D9D9;
    padding: 5px;
    margin-left: 8px;
    margin-top: -1px;
    border-radius: 6px;
}

.captcha-input {
    width: 60%;
}


.divide {
    height: 235px;
    position: relative;
    left: 74px;
    right: 74px;
    top: 48px;
}

@media screen and (max-width: 950px) {

    .divide {
        display: none;
    }

}

@media screen and (max-width: 399px) {

    .text-color-black {
        font-size: 14px !important;
    }

    .text-blue {
        font-size: 14px !important;
    }

}

@media screen and (max-width: 600px) {

    .captcha-input {
        width: 100%;
    }

}

.text-2xl {
    font-size: 1.3rem !important;
    line-height: 2rem !important;
}

.enrol-card {
    text-align: left !important;
}
</style>
