<template>
    <p class="arabic-font font-weight-semibold text-color-black mb-2 text-center mt-3 security"
       style="font-size: 14px;padding: 9px 0px;">
        {{ $t('By continuing, you agree to our') }} <br>
        <span>
            <span class="arabic-font text-blue"
                  @click="modalTerms = true"
                  style="cursor: pointer;">{{ $t('Terms & Conditions') }}</span> {{ $t('and') }}
            <!-- <span class="arabic-font text-blue"
                  @click="modalPrivacy = true"
                  style="cursor: pointer;">{{ $t('Privacy Statement') }}</span> -->
            <a v-if="selectedLanguage == 'en'" target="_blank"
               class="arabic-font text-blue"
               href="https://www.americanexpress.com.sa/content/privacy-statement"
               style="text-decoration: none;">
                {{ $t('Privacy Statement') }}
            </a>
            <a v-if="selectedLanguage == 'ar'" target="_blank"
              class="arabic-font text-blue"
               href="https://www.americanexpress.com.sa/ar/content/%D8%A8%D9%8A%D8%A7%D9%86-%D8%B6%D9%85%D8%A7%D9%86-%D8%A7%D9%84%D8%AE%D8%B5%D9%88%D8%B5%D9%8A%D8%A9"
               style="text-decoration: none;">
                {{ $t('Privacy Statement') }}
            </a>
        </span>

        <v-dialog v-model="modalTerms"
                  width="80%">
            <v-card>
                <v-card-title>
                    <span class="text-h5 text-color-black">{{ $t('Terms & Conditions') }}</span>
                    <v-spacer></v-spacer>
                    <span style="cursor: pointer;"
                          @click="modalTerms = false"
                          class="arabic-close en-close">
                        <v-icon>
                            {{ icons.mdiClose }}
                        </v-icon>
                    </span>
                </v-card-title>
                <TermConditionAr v-if="selectedLanguage == 'ar'"></TermConditionAr>
                <TermConditionEn v-if="selectedLanguage == 'en'"></TermConditionEn>
            </v-card>
        </v-dialog>

        <!-- <v-dialog v-model="modalPrivacy"
                  width="80%">
            <v-card>
                <v-card-title>
                    <span class="text-h5 text-color-black">{{ $t('Privacy Statement') }}</span>

                    <v-spacer></v-spacer>
                    <span style="cursor: pointer;"
                          @click="modalPrivacy = false"
                          class="arabic-close en-close">
                        <v-icon>
                            {{ icons.mdiClose }}
                        </v-icon>
                    </span>
                </v-card-title>
                <PrivacyTranslationAr v-if="selectedLanguage == 'ar'"></PrivacyTranslationAr>
                <PrivacyTranslationEn v-if="selectedLanguage == 'en'"></PrivacyTranslationEn>
            </v-card>
        </v-dialog> -->
    </p>
</template>

<script>
import { mdiClose } from '@mdi/js';
import store from '@/store';

import PrivacyTranslationAr from './privacy-policy-translation/PrivacyTranslationAr.vue';
import PrivacyTranslationEn from './privacy-policy-translation/PrivacyTranslationEn.vue';

import TermConditionAr from './terms-condition-translation/TermConditionAr.vue';
import TermConditionEn from './terms-condition-translation/TermConditionEn.vue';

export default {
    components: { PrivacyTranslationAr, PrivacyTranslationEn, TermConditionAr, TermConditionEn },
    data: () => ({
        modalTerms: false,
        modalPrivacy: false,
        icons: {
            mdiClose
        },
        selectedLanguage: 'en'
    }),
    mounted()
    {
        window.scrollTo(0, 0);
        this.userName = localStorage.getItem('userName');
        this.selectedLanguage = localStorage.getItem('language');
        // this.userName = store.get("requestKeys/userName");
    },
    created()
    {
        store.subscribe((mutation) =>
        {
            if (mutation.type === 'accounts/SET_LANGUAGE')
            {
                this.selectedLanguage = mutation.payload;
            }
        })
    }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 399px) {

    .text-color-black {
        font-size: 14px !important;
    }

}
</style>
