<template>
    <div>
        <div v-if="showAlert">
            <alert :message="alertMessage"
                   :type="alertType"></alert>
        </div>

        <v-form ref="loginForm"
                class="multi-col-validation"
                lazy-validation
                v-model="valid">
            <v-row>
                <v-col cols="12"
                       lg="5"
                       md="6"
                       sm="12">
                    <div>
                        <v-icon class="arabic-arrow"
                                color="green-darken-2"
                                style="font-size: 31px;cursor: pointer;"
                                @click="back">
                            mdi-arrow-left
                        </v-icon>
                        <p class="arabic-font text-lg-2xl text-xl font-weight-semibold text-black pt-5"
                           style="margin-bottom: 0;">
                            {{ $t('Enrollment Step') }} {{ '2' }} {{ $t('of') }} 3
                        </p>
                        <div style="margin: auto;">
                            <div>
                                <p class="arabic-font font-weight-semibold text-color-black mb-4 mt-8"
                                   style="font-size: 14px;">
                                    {{ $t('Please enter activation code sent to you over SMS.') }}
                                </p>
                                <div dir="ltr"
                                     class="otp-input">
                                    <v-otp-input v-model="otp"
                                                 :rules="genericRules"
                                                 @input="onOtpInput"
                                                 @blur="onInputBlur"
                                                 ref="otpInput"
                                                 :hide-details="true"
                                                 type="number"
                                                 @keydown.enter="verifyOPTAndGetDetails"
                                                 onkeydown="javascript: return event.keyCode == 69 ? false : true"
                                                 class="mb-6"
                                                 length="4">
                                    </v-otp-input>
                                    <div v-if="otpError"
                                         style="font-size: 12px;"
                                         class="ar-otp error-message">
                                        {{ $t(otpErrorMessage) }}
                                    </div>
                                </div>

                                <v-btn color="primary"
                                       type="button"
                                       style="width: 100%"
                                       :disabled="otp.length < 4"
                                       @click="verifyOPTAndGetDetails"
                                       x-large
                                       class="arabic-font mt-6 py-3">
                                    {{ $t('Next') }}
                                </v-btn>
                            </div>
                        </div>
                    </div>
                </v-col>
                <v-col class="divide">
                    <v-divider class="my-2"
                               :vertical="true"></v-divider>
                </v-col>
                <v-col cols="12"
                       lg="6"
                       md="5"
                       sm="12">
                    <div class="text-center"
                         style="padding-top: 38px;">
                        <div class="secure-img">
                            <img src="@/assets/images/logos/secure.png"
                                 alt="">
                        </div>
                        <p class="arabic-font font-weight-semibold text-color-black mb-2 mt-5">

                            {{ $t('Secure Account') }}
                        </p>
                        <p class="arabic-font font-weight-semibold text--primary mb-2 mt-5 security-text"
                           style="margin: 0 auto;font-size: 13px;">

                            {{ $t(`American Express has multiple security controls in place to ensure your information is
                            protected and safe.`) }}
                        </p>
                    </div>
                </v-col>
            </v-row>

        </v-form>
    </div>
</template>

<script>
import AppBarI18n from '@core/layouts/components/app-bar/AppBarI18n.vue'
import AppBarThemeSwitcher from '@core/layouts/components/app-bar/AppBarThemeSwitcher.vue'
import alert from '../alert.vue';
import sharedServices from '@/services/shared-services'
import enrollmentServices from '@/services/enrollment-services'
import store from '@/store'

import restErrorMessages from '@/@core/utils/rest-error-messages';

export default {
    components: {
        AppBarI18n,
        AppBarThemeSwitcher,
        alert
    },
    data: () => ({
        valid: false,
        // First Form
        legalId: '',
        captchaValue: '',
        captchaId: '',
        alertMessage: '',
        alertType: 'error',
        otpError: false,
        otpErrorMessage: '',
        showAlert: false,
        // Second Form
        otp: '',

        showOTP: false,
        captchaImage: '',
        genericRules: [
            v => !!v || 'Field is required'
        ],
        captchaRules: [
            (value) => !!value || 'Captcha is required.',
            (value) => (value && value.length >= 6) || 'Captcha must be 6 digits',
        ],

        nationalRules: [
            (value) => !!value || 'Personal ID is required.',
            (value) => (value && value.length >= 10) || 'Personal ID must be 10 digits',
        ],
    }),
    mounted()
    {
        this.requestCaptcha();
        window.scrollTo(0, 0)
    },
    methods: {
        onOtpInput()
        {

            if (this.otp.length !== 4)
            {
                this.otpError = true;
                this.otpErrorMessage = 'Field is required';
                const inputElement = this.$refs.otpInput.$el.querySelector('input');
                if (inputElement)
                {
                    inputElement.focus(); // Move focus back into the input
                }
            } else
            {
                this.otpError = false;
                const inputElement = this.$refs.otpInput.$el.querySelector('input');
                if (inputElement)
                {
                    inputElement.focus(); // Move focus back into the input
                }
            }
        },
        onInputBlur()
        {
            if (!this.otp.length)
            {
                this.otpError = true;
                this.otpErrorMessage = 'Field is required';
            }
        },
        onFinish(rsp)
        {
            this.disableSubmit = false;
        },
        submit()
        {
            this.$emit('submit')
        },
        back()
        {
            this.$emit('back')
        },
        requestCaptcha()
        {
            sharedServices.getCaptcha().then(captchaResponse =>
            {
                this.captchaImage = 'data:image/jpg;base64,' + captchaResponse.data.captcha;
                this.captchaId = captchaResponse.data.captchaId;
            })
        },
        verifyAndGetOTP()
        {
            let dict = {
                captchaId: this.captchaId,
                captchaValue: this.captchaValue,
            }

            enrollmentServices.verifyAndGetOTP(dict).then(otpResponse =>
            {
                if (otpResponse?.data?.responseInfo?.errorCode == "00000")
                {
                    this.showOTP = true;
                }
                else
                {
                    if (otpResponse?.data?.responseInfo?.errorCode != "00000")
                    {
                        this.alertMessage = restErrorMessages.get(otpResponse?.data?.responseInfo?.errorCode, otpResponse?.data?.responseInfo?.errorDescription ? otpResponse?.data?.responseInfo?.errorDescription : otpResponse?.error?.response?.data?.responseInfo?.errorDescription);
                        this.alertType = 'error'
                        this.showAlert = true
                        setTimeout(() =>
                        {
                            this.showAlert = false
                        }, 5000)
                    }

                    else if (otpResponse?.error?.response?.status != 200)
                    {
                        this.alertMessage = restErrorMessages.get(otpResponse?.data?.responseInfo?.errorCode, otpResponse?.data?.responseInfo?.errorDescription ? otpResponse?.data?.responseInfo?.errorDescription : otpResponse?.error?.response?.data?.responseInfo?.errorDescription);
                        this.alertType = 'error'
                        this.showAlert = true
                        setTimeout(() =>
                        {
                            this.showAlert = false
                        }, 5000)
                    }
                }
            })
        },
        verifyOPTAndGetDetails()
        {
            if (this.otp.length !== 4)
            {
                return;
            }
            let dict = {
                captchaId: this.captchaId,
                captchaValue: this.captchaValue,
                otp: this.otp
            }

            enrollmentServices.verifyOPTAndGetDetails(dict).then(otpResponse =>
            {
                if (otpResponse.data.responseInfo.errorCode != "00000")
                {
                    this.alertMessage = restErrorMessages.get(otpResponse?.data?.responseInfo?.errorCode, otpResponse?.data?.responseInfo?.errorDescription ? otpResponse?.data?.responseInfo?.errorDescription : otpResponse?.error?.response?.data?.responseInfo?.errorDescription);
                    this.alertType = 'error'
                    this.showAlert = true
                    setTimeout(() =>
                    {
                        this.showAlert = false
                    }, 5000)
                }
                else
                {
                    localStorage.setItem("userName", otpResponse.data.existingUserName);
                    localStorage.setItem('userData', JSON.stringify(otpResponse.data));
                    this.submit();
                }
            })
        }
    },
    watch: {
        legalId: function (newVal, oldVal)
        {
            store.set('enrollment/legalId', newVal);
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/auth.scss';

.main-width {
    width: 502px;
}

@media screen and (max-width: 630px) {
    .main-width {
        width: 100% !important;
    }

}

.secure-img {
    background: #E2F1FD;
    width: 123px;
    height: 123px;
    padding-top: 33px;
    border-radius: 79px;
    margin: 0 auto;
}

.otp-input {
    width: 290px;
}

.security-text {
    width: 260px;
}

.divide {
    height: 235px;
    position: relative;
    left: 74px;
    right: 74px;
    top: 48px;
}

@media screen and (max-width: 950px) {

    .divide {
        display: none;
    }
}

@media screen and (max-width: 399px) {

    .text-color-black {
        font-size: 14px !important;
    }

}

@media screen and (max-width: 500px) {

    .otp-input {
        width: 100% !important;
    }

    .security-text {
        width: 100%;
    }

}

.error-message {
    color: red;
    margin: -20px 10px 0px;
}
</style>
